.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: black;
  border-bottom: 1px solid var(--white);
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 1000;
}

.navbar-section {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.navbar-left, .navbar-right {
  flex: 0 0 auto;
  padding: 0 20px;
}

.navbar-center {
  flex: 1 1 auto;
  justify-content: flex-start;
  overflow-x: auto;
}

.navbar-left::after, .navbar-center::after {
  content: '';
  position: absolute;
  top: 10%;
  right: 0;
  height: 80%;
  width: 1px;
  background-color: var(--white);
}

.navbar-title {
  color: var(--white);
  font-size: 1.5rem;
  font-weight: bold;
  padding: 10px 20px 10px 10px;
}
.navbar-links {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
}

.navbar-link {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  padding: 0 10px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar-link:hover {
  color: #aaaaaa; 
}

.navbar-auth {
  position: relative;
  background-color: #333;
}

.navbar-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: black;
  border: 1px solid var(--white);
  border-top: none;
  color: #555;
}

.navbar-dropdown-item {
  display: block;
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.navbar-dropdown-item:hover {
  background-color: #333;
}
/* Card styles */
.card {
    background-color: #2a2a2a;
    width: 640px;
    height: 560px;
    border-radius: 12px;
    margin: 2rem;
    padding: 10px;
}

h2 {
    margin: 0px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
}

h3 {
    color: white;
    font-size: 1rem;
}

.card-title {
    color: white;
    font-size: 1.2rem;
}

.card-button {
  margin-top: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 12px;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  align-self: flex-start;
}

.card-button:hover {
  background-color: #555;
}

.test-card {
    background-color: #4a4a4a;
}

.puzzle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.grid-container {
    width: 100%; 
    padding-bottom: 100%; 
    position: 'relative'
}

.arrow {
    font-size: 1.5rem;
}

/* Color palette */
.color-palette {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    margin-top: 1rem;
}

.color-swatch {
    width: 30px;
    height: 30px;
    border-radius: 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .card {
        flex: 1 1 100%;
        max-width: 100%;
    }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 6px;
  background-color: #3a3a3a;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.card-button:hover {
  background-color: #4a4a4a;
}

.grid-wrapper {
  transition: transform 0.3s ease;
}

/* Metadata Card for Card Creation Styles */
.metadata-card {
  background-color: #2a2a2a;
  width: 100%;
  max-width: 1280px;
  border-radius: 12px;
  margin: 2rem auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #ffffff;
}

.metadata-card h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.metadata-card label {
  display: block;
  margin: 1rem 0 0.5rem;
  font-weight: bold;
}

.metadata-card input[type="text"],
.metadata-card textarea,
.metadata-card select {
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #444;
  background-color: #3a3a3a;
  color: #fff;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.metadata-card textarea {
  height: 100px;
  resize: none;
}

.metadata-card input[type="text"]::placeholder,
.metadata-card textarea::placeholder {
  color: #bbb;
}

.metadata-card select {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #444;
  background-color: #3a3a3a;
  color: #fff;
  font-size: 1rem;
}

.metadata-card .tags-input {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.metadata-card .tags-input input {
  flex: 1;
  min-width: 150px;
}

.metadata-card .tag {
  background-color: #444;
  padding: 5px 10px;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  margin: 0 5px 5px 0;
}

.metadata-card .tag span {
  margin-right: 10px;
}

.metadata-card .tag button {
  background: none;
  border: none;
  color: #ff4d4d;
  cursor: pointer;
}

.metadata-card .submit-btn {
  background-color: #1a73e8;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.metadata-card .submit-btn:hover {
  background-color: #155bb5;
}

/* Playcard Styles */
.play-card {
    background-color: #2a2a2a;
    width: 640px;
    height: 480px;
    border-radius: 12px;
    margin: 2rem;
    padding: 10px;
}

.playtest-card {
    background-color: #4a4a4a;
    width: 640px;
    height: 560px;
    border-radius: 12px;
    margin: 2rem;
    padding: 10px;
    transition: background-color 0.5s ease;
}

.playtest-card.correct {
    animation: flash-green 2s;
  }
  
.playtest-card.incorrect {
animation: flash-red 2s;
}
  
@keyframes flash-green {
0%, 100% { background-color: #4a4a4a; }
50% { background-color: #4CAF50; }
}
  
@keyframes flash-red {
0%, 100% { background-color: #4a4a4a; }
50% { background-color: #FF5252; }
}


/* SearchBar styles */
.search-bar-container {
  width: 100%;
  max-width: 1280px;
  text-align: left;
  margin-bottom: 40px;
}

.search-title {
  color: white;
  margin: 5px;
}

.search-bar {
  display: flex;
  margin: 5px;
  gap: 10px;
}

.search-bar input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
}

.search-bar-select {
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  width: 256px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.search-bar button {
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  width: 96px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.search-bar button:hover {
  background-color: #555;
}



.search-results {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.search-results-title {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.no-results-message {
  background-color: #333;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  color: white;
}

.load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.load-more-button {
  background-color: #4a4a4a;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #5a5a5a;
}

/* Eval Puzzle styles  */
.eval-puzzle-container {
  width: 100%;
  max-width: 1280px;
  text-align: left;
  margin-bottom: 40px;
  position: relative;
}

.eval-puzzle-title {
  color: white;
  margin: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

/* Browse Card Styles */
.browse-puzzle-container {
  width: 100%;
  max-width: 1280px;
  text-align: left;
  margin-bottom: 40px;
  position: relative;
}

.browse-puzzle-title {
  color: white;
  margin: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.browse-card {
  background-color: #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  color: white;
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  width: 100%;
  margin: 40px auto;
  margin-top: 60px;
}

.browse-card-content {
  display: flex;
  padding: 20px;
  gap: 20px;
}

.browse-card-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.browse-card-title {
  font-size: 24px;
  margin: 0;
}

.browse-card-description,
.browse-card-creator,
.browse-card-category,
.browse-card-id {
  margin: 0;
  font-size: 16px;
}

.browse-card-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.browse-card-tag {
  background-color: #555;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.browse-card-button {
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
}

.browse-card-button:hover {
  background-color: #555;
}

.browse-card-puzzles {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.browse-card-puzzle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.browse-card-puzzle-grid {
  width: 200px;
  height: 200px;
}

.refresh-button {
  margin-top: 60px;
  margin-left: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.refresh-button:hover {
  background-color: #555;
}

/* About Container */
.about-container {
  background-color: #2a2a2a;
  width: 1280px;
  border-radius: 12px;
  margin: 20px;
  padding: 20px;
}

.about-container h1, .about-container h2 {
  color: #ffffff;
  font-size: 2em; /* Adjust size as needed */
  margin: 20px 0;
}

.about-container p {
  color: #b0b0b0;
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  margin-left: 20px;
}

.about-container a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  gap: 1rem;
  padding-left: 10px;
  cursor: pointer;
}

.about-container a:hover {
  color: #aaaaaa; 
}

/* Empty Play-card Styles */
.empty-play-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
}

.empty-play-card p {
  color: #b0b0b0;
  margin-bottom: 20px;
}

.empty-play-card .button-group {
  display: flex;
  gap: 10px;
}

/* Success Styles*/
.success-container {
  width: 100%;
  max-width: 1280px;
  margin-bottom: 40px;
  position: relative;
}

.text-center {
  text-align: center;
}
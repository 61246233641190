/* App.css */

:root {
  --base: #000000;
  --white: #ffffff;
  --navbar-height: 96px;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  background-color: var(--base);
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app {
  padding-top: var(--navbar-height);
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  color: white;
}

/* Ensure grid container doesn't overlap */
.grid-container {
  position: relative;
  z-index: 1;
}